import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { navigate, graphql } from 'gatsby';
import Img, { GatsbyImageProps } from 'gatsby-image';
import get from 'lodash/get';

import { RouteComponentProps } from '@reach/router';
import {
  parseLocationSearch,
  mapSearchToTracking,
} from '../components/hooks/useTracking';

import Layout from '../components/layout';
import SEO from '../components/seo';
import LeadWizardForm, { LeadFormValues } from '../components/LeadWizardForm';

import {
  GENERAL_ZIPLINE_KEY,
  GENERAL_THANK_YOU_PAGE,
  PRIVATE_LOANS_ZIPLINE_KEY,
  GENERAL_PRIVATE_THANK_YOU,
  ZIPLINE_BASE_URL,
} from '../utils/constants';

const pageWrapCSS = css`
  font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
  text-align: center;
  line-height: 1.6em;
  color: #666;
  padding: 10px 45px;

  h2,
  h3 {
    line-height: 1.2em;
  }

  a {
    text-decoration: none;
    color: #517bfe;
  }
`;

const taglineCSS = css`
  color: #000;
  margin-bottom: 15px;
  margin-top: 10px;

  @media only screen and (max-width: 640px) {
    font-size: 24px;
  }
`;

const tagline2CSS = css`
  color: #44e08d;
  font-size: 22px;

  @media only screen and (max-width: 640px) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 640px) {
    margin-bottom: 10px;
  }
`;

const coloredHeaderCSS = css`
  font-size: 32px;
  font-weight: 400;
  color: #517bfe;
`;

const blurbSectionCSS = css`
  @media only screen and (min-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    align-items: start;

    .blurb {
      width: 50%;
    }
  }

  @media only screen and (min-width: 834px) {
    flex-wrap: nowrap;

    .blurb {
      width: 25%;
    }
  }

  .blurb {
    padding: 12px;

    h2 {
      color: #517bfe;
      font-size: 22px;
      font-weight: 400;
      padding-top: 20px;
    }

    p {
      text-align: left;
      font-size: 15px;
    }

    img {
      width: 125px;
    }
  }
`;
const bannerHolderCSS = css`
  display: none;
  margin-bottom: 0;
  object-fit: cover;
  height: 230px;
  overflow: hidden;
  @media only screen and (min-width: 640px) {
    display: block;
    width: 100%;
  }
`;

type images = 'banner' | 'stepOne' | 'stepTwo' | 'stepThree' | 'stepFour';
interface ImageProps {
  data: {
    [key in images]: {
      childImageSharp: GatsbyImageProps;
    }
  };
}

const ForgivenessPage: React.FC<RouteComponentProps & ImageProps> = props => {
  const [error, setError] = useState('');
  const [prepopValues, setPrepopValues] = useState();

  const searchObj = parseLocationSearch(props.location!.search);
  const trackingData = mapSearchToTracking(searchObj);

  useEffect(() => {
    const ppenc = get(searchObj, 'ppenc');
    if (ppenc) {
      fetch('https://www.docupop.com/bhf.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: ppenc }),
      })
        .then(resp => resp.json())
        .then(data => setPrepopValues(data))
        .catch(err => {
          console.log('error decoded ppenc', err);
        });
    }
  }, []);

  const submitLead = (values: LeadFormValues) => {
    let ziplineKey = GENERAL_ZIPLINE_KEY;
    let thanksUrl = GENERAL_THANK_YOU_PAGE;
    if (values.loans_type.includes('Private')) {
      ziplineKey = PRIVATE_LOANS_ZIPLINE_KEY;
      thanksUrl = GENERAL_PRIVATE_THANK_YOU;
    }
    return fetch(`${ZIPLINE_BASE_URL}${ziplineKey}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...values, ...trackingData }),
    })
      .then(response => {
        navigate(thanksUrl);
        return response.json();
      })
      .catch(error => {
        console.log('error', error);
        setError('There was an error submitting your data');
      });
  };

  return (
    <Layout>
      <SEO title="Get help with Student Loan Debt | DocuPop" />
      <section>
        <div css={bannerHolderCSS}>
          <Img fluid={props.data.banner.childImageSharp.fluid} />
        </div>
      </section>
      <div css={pageWrapCSS}>
        <h2 css={taglineCSS}>
          Do you have Student Loan Debt issues? We can help.
        </h2>
        <h3 css={tagline2CSS}>FREE CONSULTATION & NO OBLIGATION</h3>
        <section>
          {error && 'I had an error!'}
          <LeadWizardForm
            onSubmit={(values: LeadFormValues) => {
              console.log('submitted values', values);
              return submitLead(values);
            }}
            initialValues={prepopValues ? prepopValues : {}}
          />
        </section>
        <hr />
        <section>
          <div>
            <p>
              Or for immediate Student Loan Debt help call for a 100%
              confidential & free review:
            </p>
            <h3 css={coloredHeaderCSS}>
              <a href="tel:1-855-910-6986">(855) 910-6986</a>
            </h3>
            <strong>
              <p>YOU MAY QUALIFY TO LOWER YOUR STUDENT DEBT</p>
            </strong>
          </div>
          <div>
            <h3 css={coloredHeaderCSS}>
              WHY LET YOUR STUDENT DEBT PREVENT YOU FROM LIVING A GREAT LIFE?
            </h3>
            <p>
              You have options and we can help relieve your student debt today.
            </p>
          </div>
        </section>
        <section css={blurbSectionCSS}>
          <div className="blurb">
            <div style={{ maxWidth: 150, margin: '0 auto' }}>
              <Img
                fixed={props.data.stepOne.childImageSharp.fixed}
                style={{ height: 120 }}
              />
            </div>
            <h2>Step 1: Investigation</h2>
            <p>
              We help contact your existing lenders, gather information
              regarding your loans, payment history, balances, and condition for
              both private and federal student loans.
            </p>
          </div>
          <div className="blurb">
            <div style={{ maxWidth: 150, margin: '0 auto' }}>
              <Img
                fixed={props.data.stepTwo.childImageSharp.fixed}
                style={{ height: 120 }}
              />
            </div>
            <h2>Step 2: Analysis</h2>
            <p>
              Our team of finance professionals will review your unique
              situation and provide alternatives to your unaffordable payment
              plan, empowering you to make an educated decision
            </p>
          </div>
          <div className="blurb">
            <div style={{ maxWidth: 150, margin: '0 auto' }}>
              <Img
                fixed={props.data.stepThree.childImageSharp.fixed}
                style={{ height: 120 }}
              />
            </div>
            <h2>Step 3: Resolution</h2>
            <p>
              After reviewing your financial options, we will make
              recommendations such as flexible payment plans, refinance options,
              debt re-negotiation, loan consolidation, and deferment to reduce
              your monthly payments.
            </p>
          </div>
          <div className="blurb">
            <div style={{ maxWidth: 150, margin: '0 auto' }}>
              <Img
                fluid={props.data.stepFour.childImageSharp.fluid}
                style={{ width: 'calc(125px - 10%)', height: 127 }}
              />
            </div>
            <h2>Step 4: Completion</h2>
            <p>Your case is closed and you can live worry free.</p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ForgivenessPage;

export const pageQuery = graphql`
  query {
    banner: file(relativePath: { eq: "holiday_budget.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stepOne: file(relativePath: { eq: "step1_rep.jpg" }) {
      childImageSharp {
        fixed(width: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stepTwo: file(relativePath: { eq: "step2_folder.png" }) {
      childImageSharp {
        fixed(width: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stepThree: file(relativePath: { eq: "step3_clipboard.png" }) {
      childImageSharp {
        fixed(width: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stepFour: file(relativePath: { eq: "step4_hand.png" }) {
      childImageSharp {
        fluid(maxWidth: 125) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
